






















































import { Component, Mixins } from 'vue-property-decorator'

// components
import CoursePaymentModal from '@/components/modals/courses/CoursePaymentModal.vue'
import LoginModal from'@/components/modals/auth/LoginModal.vue'
import RegisterModal from'@/components/modals/auth/RegisterModal.vue'
import RestoreModal from '@/components/modals/auth/RestoreModal.vue'
import ItemInformation from '@/views/master/store/ItemInformation.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import VKIDMixin from '@/mixins/VKIDMixin'
// store
import AuthModule from '@/store/modules/auth'
import MasterOrdersModule from '@/store/modules/master/orders'
import {
  GroupType,
  GroupShopResource,
  MasterOrderStoreRequest,
  MasterOrderLargeResource,
  AuthRegister,
  OfferWithAuthRequest,
} from '@/store/types'

@Component({
  components: {
    CoursePaymentModal,
    LoginModal,
    RegisterModal,
    RestoreModal,
    ItemInformation,
  },
})
export default class ItemLink extends Mixins(NotifyMixin, VKIDMixin) {
  private get courseID() {
    return MasterOrdersModule.offer?.info.id as number
  }

  private get courseHash() {
    return this.$route.params.hash
  }

  private get storeCourse() {
    return MasterOrdersModule.offer?.card
  }

  private get user() {
    return AuthModule.self
  }

  private isLoading = true
  private showCourseModal = false
  private showLoginModal = false
  private showRegisterModal = false
  private showRestoreModal = false
  private selectTypeGroupIndex = 0

  private created () {
    AuthModule.fetchUser()

    if (!this.courseHash) {
      this.$router.replace({ name: this.user ? 'master.courses.my' : 'auth.login' })
        .catch(() => {return})
      this.notifyError('Некорректная ссылка')
    } else {
      this.fetchStoreCourse()
    }
  }

  private mounted() {
    this.vkAuth()
  }

  private vkAuth() {
    if(this.payload) {
      AuthModule.setVKPayload(this.payload)
      const payload = JSON.parse(this.payload)
      AuthModule.loginVK({
        id: payload.user.id,
        silentToken: payload.token,
        uuid: payload.uuid,
      })
    }
  }

  private fetchStoreCourse() {
    MasterOrdersModule.fetchOffer(this.courseHash)
      .then((response) => {
        this.orderInfo.courseId = response.info.id
      })
      .catch(this.notifyError)
      .finally(() => this.isLoading = false)
  }

  private handleBuyCourse(groupType: GroupType) {
    this.selectTypeGroupIndex = this.storeCourse?.groups?.findIndex((group: GroupShopResource) => group.type.value === groupType) || 0
    this.showCourseModal = true
    if (this.courseID === 346) {
      try {
        this.$metrika.reachGoal('basemathpay')
      } catch {
        // eslint-disable-next-line
        console.error('Отправка метрики заблокирована расширением браузера')
      }
    }
  }

  // Сохраняем выбранный в модалке курс

  private orderInfo: MasterOrderStoreRequest = {
    courseId: this.courseID,
    packageId: 0,
    groupId: 0,
  }

  private updateOrderInfo(value: MasterOrderStoreRequest) {
    this.orderInfo = {
      ...this.orderInfo,
      ...value,
    }
  }

  private createOrderWithAutorization(form: { cityId: number, email: string, password: string }) {
    MasterOrdersModule.purchaseOfferLogin({ link: this.$route.params.hash, params: { ...form, ...this.orderInfo } as OfferWithAuthRequest })
      .then((response: MasterOrderLargeResource) => {
        AuthModule.login({
          email: form.email,
          password: form.password,
        }).then(() => {
          this.$router.push({ name: 'master.payment.order', params: { orderUUID: response.uuid } })
        })
      })
      .catch(this.notifyError)
  }

  private createOrderWithRegistration(form: AuthRegister) {
    MasterOrdersModule.purchaseOfferRegister({ link: this.$route.params.hash, params: { ...form, ...this.orderInfo } })
      .then((response: MasterOrderLargeResource) => {
        AuthModule.login({
          email: form.email,
          password: form.password,
        }).then(() => {
          this.$router.push({ name: 'master.payment.order', params: { orderUUID: response.uuid } })
        })
      })
      .catch(this.notifyError)
  }
}
